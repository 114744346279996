import React from 'react';

function Input({
  id,
  name,
  value,
  onChange,
  autoComplete,
  required,
  placeholder,
  spellCheck,
  type,
  label,
  error,
  className,
  uppercase,
  autoCapitalize,
  onKeyDown,
  readOnly,
  disabled,
}) {
  return (
    <div className={`${className} w-full text-xs tracking-tight space-y-1`}>
      {label && (
        <div className="font-semibold">
          {label} {required && <span className="text-red-400">*</span>}
        </div>
      )}
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        spellCheck={spellCheck}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        disabled={disabled}
        className={`${uppercase ? 'uppercase' : ''} disabled:bg-gray-100 disabled:text-gray-400 placeholder-gray-400 text-gray-900 text-sm rounded-md p-3 w-full appearance-none focus:ring-1 focus:ring-emerald-200 border border-gray-200 focus:border-green-400 outline-none focus:outline-none transition ease-in-out duration-500`}
      />
      {error && <div className="text-xs font-light text-red-400">{error}</div>}
    </div>
  );
}

export default Input;
