import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  faCreditCard,
  faCreditCardFront,
  faEdit,
  faTrashAlt,
} from '@fortawesome/pro-duotone-svg-icons';
import _ from 'lodash';
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  notificationsAtom,
  tokenAtom,
  userPaymentsAtom,
} from '../../../../../../../atoms/Atoms';
import api from '../../../../../../../api/api';
import { H3 } from '../../../../../../shared-components/typography/Title';
import Subtitle from '../../../../../../shared-components/typography/Subtitle';
import Panel from '../../../../../../shared-components/panel/Panel';

function Options() {
  const { i18n, t } = useTranslation();
  const token = useRecoilValue(tokenAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const fetchPayment = () => {
    api
      .get('user/cards')
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setPayment({ ...payment, cards: response.data.data.results });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const deletePayment = (id) => {
    api
      .post(`user/cards/remove/${id}`, null)
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('card_eliminated_error')}`,
              error: true,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('yay')}`,
              description:
              `${t('card_eliminated_success')}`,
              error: false,
            },
          ]);
          const array = _.filter(payment.cards, (card) => card.id !== id);
          setPayment({ ...payment, cards: array });
        }
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('card_eliminated_error')} - ${error.message}`,
            error: true,
          },
        ]);
      });
  };

  useEffect(() => {
    if (token) {
      fetchPayment();
    }
  }, [token]);

  return (
    <Panel>
      <H3 text={t('payment_methods')} />
      <Subtitle
        text={t('payment_methods_prompt')}
      />
      <div className="flex items-center space-x-1 text-3xl py-4">
        <FontAwesomeIcon icon={faCcVisa} className="text-blue-600" />
        <FontAwesomeIcon icon={faCcAmex} className="text-cyan-600" />
        <FontAwesomeIcon icon={faCcMastercard} className="text-blue-800" />
        <FontAwesomeIcon icon={faCcDinersClub} className="text-lightBlue-600" />
        <FontAwesomeIcon icon={faCcDiscover} className="text-orange-400" />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <button
          type="button"
          onClick={() => {
            setPayment({ ...payment, modal: true });
          }}
          className="text-left transform hover:scale-90 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-amber-400 border border-amber-400 outline-none focus:outline-none"
        >
          <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 rounded-l-xl">
            <FontAwesomeIcon icon={faCreditCardFront} />
          </div>
          <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
            <div className="font-semibold">{t('add_new_payment')}</div>
            <div>{t('new_card')}</div>
          </div>
        </button>
        {_.map(payment.cards, (card) => (
          <div
            key={card.nickname}
            className="divide-x divide-purple-200 flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none"
          >
            <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
              <FontAwesomeIcon icon={faCreditCard} />
            </div>
            <div className="flex flex-col flex-1 justify-center text-xs p-2">
              <div className="font-semibold w-full">{card.brand}</div>
              <div>**** {card.last4}</div>
              <div>{card.expirationDate}</div>
            </div>
            <div className="flex flex-col items-center w-10">
              <button
                type="button"
                onClick={() => {
                  setPayment({ ...payment, edit: card, modal: true });
                }}
                className="rounded-tr-xl flex-1 text-purple-600 hover:text-purple-400 px-4 transition duration-500 ease-linear outline-none focus:outline-none ring-0 focus:ring-0"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                type="button"
                onClick={() => deletePayment(card.id)}
                className="rounded-br-xl flex-1 text-purple-600 hover:text-purple-400 px-4 transition duration-500 ease-linear outline-none focus:outline-none ring-0 focus:ring-0"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </Panel>
  );
}

export default Options;
