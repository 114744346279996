import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AnimatePresence, motion } from 'framer-motion';
import api from '../../../../api/api';
import {
  dayAtom,
  envAtom,
  locationAtom,
  serviceAtom,
  storeAtom,
  timeAtom,
  tokenAtom,
} from '../../../../atoms/Atoms';
import Panel from '../../../shared-components/panel/Panel';
import Loader from '../../../shared-components/loader/Loader';
import { H3 } from '../../../shared-components/typography/Title';
import Subtitle from '../../../shared-components/typography/Subtitle';
import Form from '../../../shared-components/form/Form';
import Select from '../../../shared-components/form/Select';

require('dayjs/locale/es');

dayjs.locale('es');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function Hours() {
  const token = useRecoilValue(tokenAtom);
  const env = useRecoilValue(envAtom);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  const [day, setDay] = useRecoilState(dayAtom);
  const setTime = useSetRecoilState(timeAtom);
  const location = useRecoilValue(locationAtom);
  const { t } = useTranslation();

  useEffect(() => {
    if (store && token) {
      api
        .post(
          `business/hours/${store.locations[0].id}`,
          {
            OrderTotal: 0,
            ItemCount: 0,
            OrderType: service,
            DeliveryLocationId: service === 1 ? location.location.id : null,
          }
        )
        .then((response) => {
          setDays(response.data.data.results);
          setTimes(response.data.data.results[0].times);
        })
        .catch((error) => console.log(error));
    }
  }, [token, store]);

  useEffect(() => {
    if (day) {
      const filter = _.filter(days, (d) => d.date === day);
      if (filter.length > 0) {
        setTimes(filter[0].times);
      }
    }
  }, [day]);

  return (
    <Panel>
      <H3 text={t('date')} />
      <Subtitle
        text={t('pickup_hour')}
      />
      <div className="flex items-center justify-center">
        {days.length === 0 && (
          <div
            style={{ height: 78 }}
            className="flex items-center justify-center"
          >
            <Loader />
          </div>
        )}
        <AnimatePresence>
          {days.length > 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0 }}
              className="w-full "
            >
              <Form>
                <Select
                  onChange={(e) => setDay(e.target.value)}
                  options={[
                    {
                      key: 'placeholder',
                      value: null,
                      text: t('select_date'),
                    },
                    ..._.map(days, (d, index) => ({
                      key: `day-${index}`,
                      value: d.date,
                      text: dayjs(d.date)
                        .tz(env?.timezone)
                        .format('dddd, D [de] MMMM, YYYY'),
                    })),
                  ]}
                />
                <Select
                  disabled={!day}
                  onChange={(e) => setTime(e.target.value)}
                  options={[
                    {
                      key: 'placeholder',
                      value: null,
                      text: t('select_time_placeholder'),
                    },
                    ..._.map(times, (x, index) => ({
                      key: `hour-${index}`,
                      value: x.time,
                      disabled: !x.isAvailable,
                      text: dayjs(x.endTime).tz(env?.timezone).format('h:mm A'),
                    })),
                  ]}
                />
              </Form>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Panel>
  );
}

export default Hours;
