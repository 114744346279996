import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import is from 'is_js';
import { useHistory } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeDollar } from '@fortawesome/pro-duotone-svg-icons';
import Payment from './shared-components/Payment';
import PaymentMethod from './shared-components/PaymentMethod';
import Order from './shared-components/Order';
import { checkoutSelector, minimumSelector } from '../../../atoms/Selectors';
import Hours from './shared-components/Hours';
import PromoCode from './shared-components/PromoCode';
import {
  contactAtom,
  orderAtom,
  notificationsAtom,
  orderSuccess,
  paymentAtom,
  referenceOrderAtom,
  storeAtom,
  successMessageAtom,
  tokenAtom,
  userAtom,
  locationAtom,
  serviceAtom,
  isAdditionalItemOrderAtom,
  promoCodeAtom
} from '../../../atoms/Atoms';
import Notification from './shared-components/Notification';
import Confirmation from './shared-components/Confirmation';
import Contact from './shared-components/Contact';
import api from '../../../api/api';
import { H1 } from '../../shared-components/typography/Title';
import Loader from '../../shared-components/loader/Loader';
import ExcludeOrder from './shared-components/ExcludeOrder';
import envConfig from '../../../envConfig';

function Checkout() {
  const history = useHistory();
  const checkout = useRecoilValue(checkoutSelector);
  const store = useRecoilValue(storeAtom);
  const contact = useRecoilValue(contactAtom);
  const user = useRecoilValue(userAtom);
  const token = useRecoilValue(tokenAtom);
  const setSuccess = useSetRecoilState(orderSuccess);
  const [promoCode, setPromoCode] = useRecoilState(promoCodeAtom)
  const [payment, setPayment] = useRecoilState(paymentAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [referenceOrder, setReferenceOrder] = useRecoilState(
    referenceOrderAtom
  );
  const minimum = useRecoilValue(minimumSelector);
  const [submitting, setSubmitting] = useState(false);
  const setMessage = useSetRecoilState(successMessageAtom);
  const setOrder = useSetRecoilState(orderAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const service = useRecoilValue(serviceAtom);
  const isAdditionalItemOrder = useRecoilValue(isAdditionalItemOrderAtom);
  const [needsDeliveryLocation, setNeedsDeliveryLocation] = useState(false);
  const { t } = useTranslation();

  const tabs = [
    {
      key: 'incluidos',
      title: t('items_included'),
    },
    {
      key: 'excluidos',
      title: t('items_excluded'),
    },
  ];

  const sendOrder = () => {
    setSubmitting(true);

    const mappedItems = _.map(checkout.order, (p) => ({
      businessItemId: p.id,
      amount: p.amount,
      price: p.activePrice,
      brand: p.brand,
      name: p.name,
      description: p.description,
      isEBT: p.isEBT,
      itemId: p.id,
      acceptsSubstitute: true,
      hasRandomWeight: p.hasRandomWeight,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      itemImage: p.imageUrl,
    }));

    const orderObject = {
      OGuid: uuid(),
      AppVersion: '1.0',
      PaymentType: payment.card.last4 ? 0 : payment.card.paymentType,
      OrderLat: null,
      OrderLon: null,
      BusinessId: store.id,
      CardId: payment.card.last4 ? payment.card.id : null,
      PaymentOptionId: payment.card.last4 ? null : payment.card.id,
      DeliveryLocationId: service === 1 ? location?.location?.id : null,
      DeliveryTotal: service === 1 ? store.locations[0].deliveryFee : 0,
      SubTotal: parseFloat(checkout.subtotal),
      MunicipalTax: parseFloat(checkout.muniTax),
      StateTax: parseFloat(checkout.stateTax),
      TaxTotal: parseFloat(checkout.muniTax) + parseFloat(checkout.stateTax),
      ProcessingFee:
        service === 0 ? parseFloat(store.locations[0].pickupFee) : 0,
      OrderTotal: parseFloat(checkout.total),
      LocationId: store.locations[0].id,
      PickUpName: `${contact.name} ${contact.phone}`,
      SpecialInstruction: contact.instructions,
      Type: 0,
      items: mappedItems,
      DesiredDateTime: checkout.time,
      StartSlotTime: checkout.startSlotTime,
      EndSlotTime: checkout.endSlotTime,
      BypassMinimum: referenceOrder !== null || checkout.hasMinimum,
      IsAdditionalItemOrder: referenceOrder !== null && isAdditionalItemOrder,
      ReferenceOrderId: referenceOrder?.id,
      CouponId: promoCode?.id
    };

    api
      .post('orders', orderObject)
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: t('error_submitting_order'),
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setMessage(response.data);
          setSuccess(true);
          if (referenceOrder) {
            setReferenceOrder(null);
          }
          if (
            orderObject.PaymentOptionId &&
            !payment.card.allowNonFoodProducts &&
            _.filter(checkout.allItems, (product) => !product.isEBT)
          ) {
            setReferenceOrder(response.data.data);
            setOrder(_.filter(checkout.allItems, (product) => !product.isEBT));
          } else {
            setOrder([]);
          }
          localStorage.removeItem(`${envConfig.StorageKey}-order`);
          const tempPayment = JSON.parse(JSON.stringify(payment));
          tempPayment.card = null;
          localStorage.removeItem(`${envConfig.StorageKey}-card`);
          localStorage.removeItem(`${envConfig.StorageKey}-payment`);
          setPayment(tempPayment);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: t('error_submitting_order'),
            description: error.message,
            error: true,
          },
        ]);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (service === 1 && location.location === null) {
      setNeedsDeliveryLocation(true);
      setLocation({ ...location, modal: true, preventRedirect: true });
    }
    setReferenceOrder(null);
  }, []);

  useEffect(() => {
    if (promoCode) {
      if (promoCode.minimumAmount > checkout.subtotal) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_minimum').format(promoCode.minimumAmount.toFixed(2)),
            error: true,
          },
        ]);
      } else if (promoCode.appliesTo === 0 && service !== 0) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_pickup'),
            error: true,
          },
        ]);
      } else if (promoCode.appliesTo === 1 && service !== 1) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_delivery'),
            error: true,
          },
        ]);
      }
    }
  }, [checkout, service]);

  // useEffect(() => {
  //   if (!location.modal && needsDeliveryLocation && location.location === null) {
  //     history.push('/');
  //   }
  // }, [location]);

  return (
    <>
      <div className="sticky top-0 bg-white p-4 border-b border-gray-200 text-right">
        <H1 text={t('your_order')} />
        <div className="text-sm font-semibold">
          {t('subtotal')}: ${checkout.subtotal}
        </div>
        <div className="text-xs tracking-tight">
          {t('order_service')}: {service === 0 ? t('pickup') : t('delivery')}
        </div>
        {service === 1 && (
          <>
            <div className="text-xs tracking-tight">
              {t('delivery_at')}: {location.location?.name}
            </div>
            <div className="text-xs tracking-tight">
              {location.location?.addressLine}
            </div>
          </>
        )}
        {service === 0 && (
          <div className="text-xs tracking-tight">
            {t('pickup_at')}: {store?.name}
          </div>
        )}
        {!referenceOrder && (
          <div className="text-xs font-semibold text-rose-500">
            {t('minimum_purchase').format(minimum?.toFixed(2))}
          </div>
        )}
      </div>
      <div className="md:flex flex-1 overflow-y-auto md:overflow-y-hidden">
        <PaymentMethod />
        <Confirmation />
        <div className="flex flex-col md:flex-row flex-1">
          <div className="p-4 w-full md:w-1/4 overflow-y-auto flex-shrink-0">
            <Tab.Group>
              <Tab.List className="flex space-x-1 p-2 bg-gray-900 bg-opacity-10 rounded-xl text-xs">
                {_.map(tabs, (e) => (
                  <Tab
                    key={e.key}
                    className={({ selected }) => {
                      if (selected) {
                        return 'bg-white shadow text-rose-500 w-full py-2.5 text-xs leading-5 font-medium rounded-md outline-none focus:outline-none';
                      }
                      return 'text-gray-700 w-full py-2.5 text-xs leading-5 font-medium rounded-md hover:bg-white hover:bg-opacity-50';
                    }}
                  >
                    {e.title} ({e.key === 'incluidos' ? checkout.order.length : checkout.excludedItems.length})
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className="p-4">
                  <Order />
                </Tab.Panel>
                <Tab.Panel className="p-4">
                  <ExcludeOrder />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
          <motion.div
            initial={{
              x: 50,
              opacity: 0,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            exit={{
              y: 50,
              opacity: 0,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            className="flex-2 overflow-y-auto w-full md:bg-white border-t border-l md:rounded-tl-xl p-4 space-y-4 md:mt-4"
          >
            <PromoCode />
            <Hours />
            <Contact />
            <Payment />
            <AnimatePresence>
              {!is.all.existy(
                token,
                user,
                payment.card,
                checkout.time,
                checkout.order.length > 0,
                store,
                checkout.hasMinimum
              ) && <Notification />}
            </AnimatePresence>
            <div className="flex items-center grid grid-cols-1 md:grid-cols-2 gap-4 py-4">
              <div className="text-sm text-right md:text-left">
                <div className=" text-gray-400">
                  {t('subtotal')}:{' '}
                  <span className="text-emerald-500 font-medium">
                    ${checkout.subtotal}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('municipal_tax')}:{' '}
                  <span className="text-emerald-500 font-medium">
                    ${checkout.muniTax}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('state_tax')}:{' '}
                  <span className="text-emerald-500 font-medium">
                    ${checkout.stateTax}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('order_service_charge')}:{' '}
                  <span className="text-emerald-500 font-medium">
                    ${checkout.serviceCost?.toFixed(2)}
                  </span>
                </div>
                {promoCode?.id && (
                  <div className=" text-gray-400">
                    {t('discount')}:{' '}
                    <span className="text-emerald-500 font-medium">
                      ${checkout.discountTotal?.toFixed(2)}
                    </span>
                  </div>
                )}
              </div>
              <button
                type="button"
                disabled={
                  is.any.falsy(
                    token,
                    user,
                    payment.card,
                    checkout.time,
                    checkout.order.length > 0,
                    store,
                    checkout.hasMinimum
                  ) || submitting
                }
                className="flex flex-1 items-center justify-between p-4 hover:shadow-md rounded-xl text-sm font-semibold tracking-tight text-white ring-2 ring-emerald-500 ring-offset-2 bg-emerald-500 hover:bg-emerald-400 disabled:cursor-not-allowed disabled:opacity-50 outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                onClick={() => sendOrder()}
              >
                <motion.div
                  whileHover={{ scale: 1.2, rotate: 360 }}
                  className="text-xs flex items-center justify-center h-6 w-6 bg-emerald-700 rounded-md"
                >
                  {checkout.order.length}
                </motion.div>
                <div className="flex items-center justify-center w-1/2 text-center">
                  {submitting ? (
                    <Loader color="bg-white" />
                  ) : (
                    <span>{t('make_order')}</span>
                  )}
                </div>
                <div className="flex items-center space-x-1">
                  <FontAwesomeIcon icon={faBadgeDollar} className="text-2xl" />
                  <div className="text-xs">{checkout.total}</div>
                </div>
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
