import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilValue } from 'recoil';
import { storeAtom } from '../../../../atoms/Atoms';

function Breadcrumb() {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);

  return (
    <div className="container mx-auto flex flex-shrink-0 items-center w-full text-xs tracking-tight px-4 pt-4 space-x-1 truncate">
      <NavLink
        to="/"
        className="hover:text-emerald-500 transition duration-500 ease-in-out"
      >
        {t('home')}
      </NavLink>
      <FontAwesomeIcon icon={faChevronRight} />
      <NavLink
        to={`/stores/${store && store.id}`}
        className="hover:text-emerald-500 transition duration-500 ease-in-out"
      >
        {store && store.name}
      </NavLink>
      <FontAwesomeIcon icon={faChevronRight} />
      <div className="text-gray-400">{t('departments')}</div>
    </div>
  );
}

export default Breadcrumb;
