import React, { useState } from 'react';
import _ from 'lodash';
import {
  useRecoilValueLoadable,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import ItemsCarousel from 'react-items-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToLeft,
  faArrowToRight,
} from '@fortawesome/pro-duotone-svg-icons';
import { infoCardsSelector } from '../../../../../atoms/Selectors';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import Loader from '../../../../shared-components/loader/Loader';

function Carousel() {
  const { t } = useTranslation();
  const infoCards = useRecoilValueLoadable(infoCardsSelector);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const breakpoint = useBreakpoint();

  
  function itemsToShow() {
    if (breakpoint === 2) { return 1; } 
    if (breakpoint === 3) { return 2; }
    if (breakpoint === 4) { return 3; }
    return 5;
  }

  return (
    <div className="space-y-4 py-10 border-t border-b bg-white">
      {infoCards.state === 'loading' && (
        <div className=" flex flex-1 flex-col items-center justify-center h-20">
          <Loader />
        </div>
      )}
      {infoCards.state === 'hasValue' && infoCards.contents?.data?.data?.results?.length > 0 && (
        <div>
          <div className="mx-6 px-4">
            <div className="text-sm tracking-tight font-light">
              {t('find_what_you_need')}
            </div>
            <div className="text-3xl font-extrabold tracking-tight">{t('offers')}</div>
          </div>
          <ItemsCarousel
            infiniteLoop
            gutter={10}
            activePosition="right"
            chevronWidth={50}
            disableSwipe={false}
            alwaysShowChevrons
            numberOfCards={itemsToShow()}
            slidesToScroll={itemsToShow()}
            showSlither
            firstAndLastGutter
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full"
              >
                <FontAwesomeIcon icon={faArrowToRight} />
              </button>
            }
            leftChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full"
              >
                <FontAwesomeIcon icon={faArrowToLeft} />
              </button>
            }
          >
            {_.map(
              infoCards.state === 'hasValue' &&
              infoCards.contents.data.data.results,
              (s) => (
                <div key={s.id} className="mx-auto">
                  {!s.action && (
                    <img
                      alt={s.name}
                      src={s.imageUrl}
                      className="rounded-xl m-5 h-52 mx-auto"
                    />
                  )}
                  {s.action && (
                    <button
                      type="button"
                      className="m-5 mx-auto"
                      onClick={() => {
                        if (s.action.includes('openlink?url=')) {
                          const url = s.action.replace('openlink?url=', '');
                          window.open(url, '_blank').focus();
                        }
                      }}
                    >
                      <img
                        alt={s.name}
                        src={s.imageUrl}
                        className="rounded-xl h-52 mx-auto"
                      />
                    </button>
                  )}
                </div>
              )
            )}
          </ItemsCarousel>
        </div>
      )}
    </div>
  );
}

export default Carousel;
