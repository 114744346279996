import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faBus } from '@fortawesome/pro-duotone-svg-icons';

function ServiceSelection() {
const [storeDrawer, setStoreDrawer] = useState(false);

    return (<div className="flex md:flex-row flex-col md:items-center space-between mt-4">         
                <div 
                  role="button"  
                  tabIndex={-1}            
                  onClick={() => {
                    setStoreDrawer(true);
                  }}
                  className="bg-base flex flex-row rounded-xl border text-white p-4 w-full md:w-1/2 mr-4 shadow h-28 md:h-30"
                >
                  <FontAwesomeIcon className="text-4xl md:text-5xl mt-2 mr-6" icon={faStore} />                  
                  <div className="flex flex-col">
                    <div className="w-full font-semibold text-md md:text-md">Pickup</div>
                    <div className="w-full font-light text-xs md:text-md overflow-hidden">Selecciona una tienda en donde 
                        recoger la compra en un horario disponible.
                    </div>
                  </div>
                </div>
                <div 
                  role="button"  
                  tabIndex={0}            
                  onClick={() => {
                    setStoreDrawer(true);
                  }}
                  className="bg-base flex flex-row rounded-xl border text-white p-4 w-full md:w-1/2 my-4 md:my-2 md:ml-4 shadow h-28 md:h-30"
                >
                  <FontAwesomeIcon className="text-4xl md:text-5xl mt-2 mr-6" icon={faBus} />      
                  <div className="flex flex-col">
                    <div className="w-full font-semibold text-md md:text-md">Delivery</div>
                    <div className="w-full font-light text-xs  md:text-md overflow-hidden">Escoge un lugar de entrega para recibir 
                    tu compra en una hora seleccionada.
                    </div>
                  </div>
                </div>
            </div>)
}

export default ServiceSelection;
