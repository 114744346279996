const envConfig = {
  API_KEY: process.env.REACT_APP_API_KEY ?? 'c6e5be5365984c75af234452e18a9b0c',
  API_URL: process.env.REACT_APP_API_URL ?? 'https://grocefy-dev.azure-api.net/web/',
  HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://grocefy-dev-api.azurewebsites.net/',
  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN ?? 'pk.eyJ1IjoiZ29wYW56YS1tYXBib3giLCJhIjoiY2tqeGFnZGJ6MDJncTJvazdqYzd6OHczYSJ9.9ymsPgBfRUtD8O_M8yyGEw',
  PAYPAL_CLIENTID: process.env.REACT_APP_PAYPAL_CLIENTID ?? '#{REACT_APP_PAYPAL_CLIENTID}#',
  GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyB6jl09IPMyXwc55wjIj40Mi9p2ABtZKf0',
  SearchItemsPageSize: 14,
  TemplateVersion: process.env.REACT_APP_TEMPLATE_VERSION ?? '0',
  HeaderVersion: process.env.REACT_APP_HEADER_VERSION ?? '0',
  StorageKey: process.env.REACT_APP_STORAGE_KEY ?? '#{REACT_APP_STORAGE_KEY}#',
};

export default envConfig;
