import React from 'react';

function Service({ title, body, image, note, onClick, buttonText }) {
  return (
    <div className="space-y-4 flex flex-col items-center justify-center text-center text-sm bg-white shadow-sm rounded-xl p-4">
      <img alt={title} src={image} className="h-14 w-14" />
      <div className="font-semibold tracking-tight">{title}</div>
      <div>{body}</div>
      <div className="text-xs text-rose-500">{note}</div>
      {buttonText && (
        <button
          type="button"
          onClick={onClick}
          className="text-white font-semibold bg-emerald-400 hover:bg-emerald-500 rounded p-3 transition duration-500 ease-linear ring-0 focus:ring-0 outline-none focus:outline-none"
        >
          {buttonText}
        </button>
      )}
    </div>
  );
}

export default Service;
