import React, { useState } from 'react';
import _ from 'lodash';
import {
  useRecoilValueLoadable,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import ItemsCarousel from 'react-items-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToLeft,
  faArrowToRight,
} from '@fortawesome/pro-duotone-svg-icons';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { bannerAdsListSelector } from '../../../atoms/Selectors';

function BannerCarousel() {
const { t } = useTranslation();
const banners = useRecoilValueLoadable(bannerAdsListSelector);
const [activeItemIndex, setActiveItemIndex] = useState(0);
const breakpoint = useBreakpoint();

  return (
    <div className="space-y-4 py-4 bg-white">
      {banners.state === 'loading' && (
        <div className=" flex flex-1 flex-col items-center justify-center h-20">
         Loading...
        </div>
      )}
       {banners.state === 'hasValue' && banners.contents.data.data.tops.length > 0 && (
        <div className="w-full" hidden={breakpoint && breakpoint < 4}>
          <div className="mx-6 px-2">
            <div className="text-sm tracking-tight font-light">
              Carrusel de Imagenes
            </div>
            <div className="text-3xl font-extrabold tracking-tight">Banners</div>
          </div>
          <ItemsCarousel
            gutter={10}
            infiniteLoop
            activePosition="center"
            chevronWidth={50}
            disableSwipe={false}
            alwaysShowChevrons
            numberOfCards={1}
            slidesToScroll={1}
            showSlither            
            firstAndLastGutter
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={
              <button
                type="button"
                hidden={banners && banners.contents.data.data.tops.length < 2}
                className="flex items-center justify-center h-10 w-10 bg-base shadow rounded-full text-white hover:bg-accentdark transition duration-300 ease-linear"
              >
                <FontAwesomeIcon icon={faArrowToRight} />
              </button>
            }
            leftChevron={
              <button
                type="button"
                hidden={banners && banners.contents.data.data.tops.length < 2}
                className="flex items-center justify-center h-10 w-10 bg-base shadow rounded-full text-white hover:bg-accentdark transition duration-300 ease-linear"
              >
                <FontAwesomeIcon icon={faArrowToLeft} />
              </button>
            }
          >
            {_.map(banners && banners.contents.data.data.tops,
              (s) => (
                <div key={s.id} className="mx-auto">
                  {!s.action && (
                    <img
                      alt={s.name}
                      src={s.imageUrl}
                      className="h-52 mx-auto"
                    />
                  )}
                  {s.action && (
                    <button
                      type="button"
                      className="m-5 mx-auto"
                      onClick={() => {
                        if (s.action.includes('openlink?url=')) {
                          const url = s.action.replace('openlink?url=', '');
                          window.open(url, '_blank').focus();
                        }
                      }}
                    >
                      <img
                        alt={s.name}
                        src={s.imageUrl}
                        className="h-52 mx-auto"
                      />
                    </button>
                  )}
                </div>
              )
            )}
          </ItemsCarousel>
        </div>
      )}
    </div>
  );
}

export default BannerCarousel;
