import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import Banner1 from '../../../../../assets/img/services/services_pickup.png';
import Banner2 from '../../../../../assets/img/services/services_delivery.png';
import Banner3 from '../../../../../assets/img/services/services_offers.png';
import Service from './shared-components/Service';
import {
  locationAtom,
  storesDialogAtom,
  userAtom,
} from '../../../../../atoms/Atoms';

function Services() {
  const { t } = useTranslation();
  const setOpen = useSetRecoilState(storesDialogAtom);
  const profile = useRecoilValue(userAtom);
  const [location, setLocation] = useRecoilState(locationAtom);

  const services = [
    {
      id: '1',
      title: t('express_orders'),
      body: t('pickup_order_description'),
      note: '',
      image: Banner1,
      buttonText: t('start_pickup_order'),
      onClick: () => setOpen(true),
    },
    {
      id: '2',
      title: t('carry_the_packages'),
      body: t('delivery_order_description'),
      note: '',
      buttonText: t('start_delivery_order'),
      image: Banner2,
      onClick: () => {
        setLocation({
          ...location,
          modal: true,
          page: 0,
          current: 'locations',
        })
      }
    },
    {
      id: '3',
      title: t('loyalty_description'),
      body: t('find_the_best'),
      note: '',
      image: Banner3,
    },
  ];

  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 py-12 px-4">
      {_.map(
        services,
        ({ id, title, body, note, image, buttonText, onClick }) => (
          <Service
            key={id}
            title={title}
            body={body}
            note={note}
            image={image}
            buttonText={buttonText}
            onClick={onClick}
          />
        )
      )}
    </div>
  );
}

export default Services;
