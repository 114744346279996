import React from 'react';
import { motion } from 'framer-motion';
import { useUnmount } from 'react-use';
import { useSetRecoilState } from 'recoil';
import Banner from './shared-components/banner/Banner';
import Footer from './shared-components/footer/Footer';
import Carousel from './shared-components/carousel/Carousel';
import Services from './shared-components/services/Services';
import StoreDialog from './shared-components/StoreDialog';
import { storesDialogAtom } from '../../../atoms/Atoms';
import DynamicBanner from '../../shared-components/dynamic-banner/DynamicBanner';
import BrandCard from '../../shared-components/brand-card/BrandCard';
import FeedbackFooter from '../../shared-components/feedback-footer/FeedbackFooter';
import envConfig from '../../../envConfig';
import Welcome from '../../shared-components/welcome/Welcome';
import BannerCarousel from '../../shared-components/banner-carousel/BannerCarousel';
import ServiceSelection from '../../shared-components/service-selection/ServiceSelection';

function Home() {
  const setDialog = useSetRecoilState(storesDialogAtom);

  useUnmount(() => {
    setDialog(false);
  });

  return envConfig.HeaderVersion === '0' ? (
    <div className="flex-1 overflow-y-auto">
      <motion.div
        initial={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        exit={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
      >
        <StoreDialog />
        <Banner />
        <Carousel />
        <Services />    
        <Footer />
      </motion.div>
    </div>
  ) : 
  (
  <div className="flex-1 overflow-y-auto bg-white">
  <motion.div
    initial={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    exit={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
  >
    <StoreDialog />
    <div className="flex flex-col px-2 sm:px-12 md:px-24 lg:px-52">
      {/* <DynamicBanner />   */}
      <Welcome />   
      <ServiceSelection />
      <BannerCarousel />
      <Carousel />       
      <BrandCard />
    </div>
    <FeedbackFooter />
    <Footer />
  </motion.div>
  </div>);
}

export default Home;
