import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValueLoadable } from 'recoil';
import { bannerAdsSelector } from '../../../../../atoms/Selectors';
import Loader from '../../../../shared-components/loader/Loader';

function Banner() {
  const banners = useRecoilValueLoadable(bannerAdsSelector);
  const { t } = useTranslation();

  return (
    <>
      {banners?.state === 'loading' && (
        <div className=" flex flex-1 flex-col items-center justify-center h-20">
          <Loader />
        </div>
      )}
      {banners?.state === 'hasValue' && !banners?.contents?.data?.data?.top && (
        <div className="flex flex-shrink-0 text-white bg-freshblack bg-center bg-cover h-28 md:h-72">
          <div className="flex flex-col flex-1 justify-end p-4" />
        </div>
      )}
      {banners?.state === 'hasValue' && banners?.contents?.data?.data?.top && (
        <div
          aria-hidden="true"
          className={`flex flex-shrink-0 text-white bg-center bg-cover h-28 md:h-72 ${banners?.contents?.data?.data?.top?.actionType === 1 ? 'clickable' : ''}`}
          onClick={() => {
            if (banners?.contents?.data?.data?.top?.actionType === 1) {
              window.open(banners?.contents?.data?.data?.top?.actionValue, '_blank');
            }
          }}
          style={{ backgroundImage: `url(${banners?.contents?.data?.data?.top?.imageUrl})` }}
        >
          <div className="flex flex-col flex-1 justify-end p-4" />
        </div>
      )}
    </>
  );
}

export default Banner;
