import React from 'react';

function Welcome() { 
    return (
    <div className="my-2 text-gray-600 flex flex-col justify-center text-sm sm:text-md w-full">
      <div className="self-center border border-2xl rounded-xl shadow bg-accentdark overflow-hidden h-12">
        <div
          className="text-white font-light mx-2 my-1 tracking-wide text-ellipsis hidden md:block"
        >Bienvenido a ReadyKart
          Encuentra en ReadyKart de Supermercados Hatillo Kash N’ Karry 
          los mejores productos 
          y especiales con la calidad
          y variedad que nos caracteriza. Regístrate y recibe las recetas, 
          el folleto semanal y comienza a ahorrar dinero.el 
          folleto semanal y 
          comienza a ahorrar dinero.
        </div>
        <div 
          className="text-white font-light mx-2 my-1 self-center md:hidden"     
        >Bienvenido a ReadyKart. Escoge que servicios deseas utilizar.
        </div>
      </div>
    </div>)
}
export default Welcome;


