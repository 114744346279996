import React, { useRef, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { alertAtom } from '../../atoms/Atoms';
import { backdropVariants, modalVariants } from '../../utils';

function Alert() {
  const ref = useRef();
  const [state, setState] = useRecoilState(alertAtom);

  useClickAway(ref, () => {
    setState({
      ...state,
      show: false,
    });
  });

  useEffect(() => {
    if (!state.show) {
      setState({
        ...state,
        title: '',
        content: '',
        onClick: null,
      });
    }
  }, [state.show]);

  return (
    <AnimatePresence>
      {state.show && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-40 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div ref={ref} className="mx-auto max-w-md px-4">
              <div className="rounded-xl shadow-lg relative flex flex-col w-full bg-white p-4 space-y-4">
                <div className="text-center font-bold tracking-tight">
                  {state.title}
                </div>
                <div className="text-gray-500 text-sm text-center">
                  {state.content}
                </div>
                <div className="flex items-center justify-center">
                  <button
                    className="text-gray-400 hover:text-gray-500 font-semibold px-4 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setState({
                        ...state,
                        show: false,
                      });
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    className="flex items-center justify-between px-4 py-2 hover:shadow-md rounded-xl text-sm font-semibold tracking-tight text-white ring-2 ring-emerald-500 ring-offset-2 bg-emerald-500 hover:bg-emerald-400 disabled:cursor-not-allowed disabled:opacity-50 outline-none focus:outline-none transition duration-500 ease-in-out"
                    type="button"
                    onClick={state.onClick}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Alert;
